<template>
    <!-- Footer -->
<footer class="bg-light text-center text-lg-start">
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="text-center p-1" style="background-color: rgba(0, 0, 0, 0.2);">
    <span class="text-dark">© {{year}} Copyright: Orca IoT Inc.</span>
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->
</template>
<script>
export default {
    data(){
        return {
            year: ""
            }
    },
    mounted(){
        const date = new Date();
        // date.getFullYear()
        this.year = date.getFullYear();
    }
}
</script>
<style scoped>
footer {
    position: fixed;
    /* height: 100px; */
    bottom: 0;
    width: 100%;
}
</style>