import {
   createStore
} from "vuex"
import Auth from '@aws-amplify/auth'
import axios from 'axios'

const store = createStore({
   state: {
      jwtTimeout: "",
      jwtToken: "",
      email: "",
      empID: "",
      dashboardEmpID: ""

   },
   mutations: {

      SETSESSIONTIMEOUT: (state, payload) => {
         state.jwtTimeout = payload[0]
         state.jwtToken = payload[1]
      },
      SETUSEREMAIL: (state, payload) => {
         state.email = payload
      },
      SETEMPID: (state, payload) => {
         state.empID = payload
      },
      SETDASHBOARDEMPID: (state, payload) => {
         state.dashboardEmpID = payload
      },

   },
   actions: {
      async login() {
         try {

            await Auth.federatedSignIn({
               provider: 'Google'
            })
         } catch (err) {
            console.log(err);
         }
      },
      async setSessionTimeout({
         commit,
         dispatch
      }) {
         const user = await Auth.currentAuthenticatedUser()

         var utcSeconds = 1614365337;
         var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
         d.setUTCSeconds(utcSeconds);
         console.log(user)
         commit('SETSESSIONTIMEOUT', [user.signInUserSession.accessToken.payload.exp, user.signInUserSession.accessToken.jwtToken])
         commit('SETUSEREMAIL', user.attributes.email)

         dispatch('getLoginInfo', [user.signInUserSession.accessToken.jwtToken, user.attributes.email])
         setInterval(() => dispatch('checkVersion', user.signInUserSession.accessToken.jwtToken), 1200000);

      },
      logout() {
         try {
            Auth.signOut();

         } catch (err) {
            console.log(err);
         }
      },
      async refreshToken({
         commit
      }) {
         try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const currentSession = await Auth.currentSession();
            cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
               commit('SETSESSIONTIMEOUT', [session.accessToken.payload.exp, session.accessToken.jwtToken]);

            });
         } catch (e) {
            console.log(e)

         }
      },

      logMessage(store, payload) {

         console.log(store)
         axios.post("https://logs-01.loggly.com/inputs/5c7f96d1-f4fb-4746-a1dd-12c4dde732a1/tag/backend/", {
            payload
         })
      },

      getLoginInfo({
         commit
      }, payload) {

         const headers = {
            'x-orca-accesstoken': payload[0],
         };

         axios.get(process.env.VUE_APP_ROOT_API + "employees/details", {
               headers
            })
            .then(response => {

               let employees = response.data.body

               let employee = employees.filter(employees => employees.email === payload[1])

               commit('SETEMPID', employee[0].empID);
               commit('SETDASHBOARDEMPID', employee[0].empID);

            })

      },

      setDashboardEmpID({
         commit
      }, payload) {
         commit('SETDASHBOARDEMPID', payload);
      },
      sortTable({
         commit}, {rows,sortOrder,sortName,dataType}) { // eslint-disable-line no-unused-vars

         if (dataType === 'string') {
            if (sortOrder.value[sortName] === 'dsc') {
               rows.value.sort((a, b) => {
                     if (a[sortName] === "" || a[sortName] === null || a[sortName] === "None") return 1;
                     if (b[sortName] === "" || b[sortName] === null || b[sortName] === "None") return -1;
                     if (a[sortName].trim().toUpperCase() === b[sortName].trim().toUpperCase()) return 0;
                     return a[sortName].trim().toUpperCase() < b[sortName].trim().toUpperCase() ? 1 : a[sortName].trim().toUpperCase() > b[sortName].trim().toUpperCase() ? -1 : 0;
                 })
             } else if (sortOrder.value[sortName] === '' || sortOrder.value[sortName] === 'asc') {
               rows.value.sort((a, b) => {
                     if (a[sortName] === null || a[sortName] === "None") return 1;
                     if (b[sortName] === null || b[sortName] === "None") return -1;
                     if (a[sortName].trim() === "") return 1;
                     if (b[sortName].trim() === "") return -1;
                     if (a[sortName].trim().toUpperCase() === b[sortName].trim().toUpperCase()) return 0;
                     return a[sortName].trim().toUpperCase() < b[sortName].trim().toUpperCase() ? -1 : 1;
                 })
             }
         } else if (dataType === 'int') {

            if (sortOrder.value[sortName] === 'dsc') {
               rows.value.sort((a, b) => {
                  if (a[sortName] === "" || a[sortName] === null) return -1;
                  if (b[sortName] === "" || b[sortName] === null) return 1;
                  if (Number(a[sortName]) === Number(b[sortName])) return 0;
                  return Number(a[sortName]) < Number(b[sortName]) ? -1 : 1;
               })
            } else if (sortOrder.value[sortName] === '' || sortOrder.value[sortName] === 'asc') {
               rows.value.sort((a, b) => {
                  if (a[sortName] === "" || a[sortName] === null) return 1;
                  if (b[sortName] === "" || b[sortName] === null) return -1;
                  if (Number(a[sortName]) === Number(b[sortName])) return 0;
                  return Number(a[sortName]) > Number(b[sortName]) ? -1 : 1;
               })
            }
         } else if (dataType === 'date') {

            if (sortOrder.value[sortName] === 'dsc') {
               rows.value.sort((a, b) => {
                  if (a[sortName] === '') return 1
                  if (b[sortName] === '') return -1
                  return new Date(b[sortName]) > new Date(a[sortName]) ? -1 : 1;
               });
            } else if (sortOrder.value[sortName] === '' || sortOrder.value[sortName] === 'asc') {
               rows.value.sort((a, b) => {
                  if (a[sortName] === '') return 1
                  if (b[sortName] === '') return -1
                  return new Date(b[sortName]) < new Date(a[sortName]) ? -1 : 1;
               });
            }

         }



      },
      resetSortArrows({
         commit}, {sortOrder,sortName}) { // eslint-disable-line no-unused-vars

         for (let x in sortOrder.value) {
            if (x != sortName) {
               sortOrder.value[x] = ''
            } else {
               if (sortOrder.value[x] === 'asc' || sortOrder.value[x] === '') {
                  sortOrder.value[x] = 'dsc'
               } else {
                  sortOrder.value[x] = 'asc'
               }
            }
         }
         return sortOrder
      },

      checkVersion({commit}, jwtToken) { // eslint-disable-line no-unused-vars

         const headers = {
            'x-orca-accesstoken': jwtToken,
         };

         axios.get(process.env.VUE_APP_ROOT_API + "versions", {
               headers
            })
            .then(response => {

               if (response.data.body.version != process.env.VUE_APP_VERSION){
                  window.location.reload()
               }


            })

      }






   },
   getters: {

      getSessionTimeout: (state) => state.jwtTimeout,
      getCurrentTime: () => Math.round(Date.now() / 1000),
      getJwtToken: (state) => state.jwtToken,

   }
})

export default store