<template>
    <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
        <button @click="hideLogout()" style="margin-left: 1em" class="navbar-toggler" type="button"
            data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div id="navbarBrandProject" class="navbar-brand">
            Orca IoT
        </div>
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav w-100" style="margin-left: 1em">
                <li :class="selectedLink === 'home' ? 'nav-item dropdown active' : 'nav-item dropdown'">
                    <router-link class="nav-link" to="/"><span>Home</span>
                    </router-link>
                </li>

                <li class="nav-item dropdown">
                    <a :class="selectedLink === 'diag' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'"
                        id="diagDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        Diag
                    </a>
                    <div class="dropdown-menu dropdown" aria-labelledby="diagDropdown">
                        <router-link to="/diag/priority"><span
                                :class="selectedLink === 'diag' && selectedSubLink === 1 ? 'dropdown-item bg-dark text-white' : 'dropdown-item'">Priority</span>
                        </router-link>
                    </div>
                </li>

                <div class="w-100"></div>

                <li class="nav-item dropdown">
                    <a style="margin-right: 1em" class="nav-link logout dropdown-toggle" id="dropdownMenuButton"
                        data-toggle="dropdown" href="#">Logout</a>
                    <div class="dropdown-menu dropdown dropdown-menu-right p-2 text-center"
                        aria-labelledby="dropdownMenuButton">
                        <span class="dropdown-item"><strong>Username: </strong>{{username}}</span>
                        <span class="dropdown-item"><strong>Email: </strong>{{email}}</span>
                        <button @click.prevent="logout()" class="btn btn-primary mt-4">Logout</button>
                    </div>
                </li>
            </ul>
        </div>

        <!-- <div>
            <ul class="navbar-nav ml-auto">
                <li class="nav-item dropdown dropdown-toggle active nav">
                    <input autocomplete="off" :class="hiddenClass ? 'hide' : ''" type="search" id="searchDropdown"
                        class="form-control rounded" placeholder="Search" v-model="search" />
                    <teleport to="#modal">
                        <div :class="showModalMask ? 'modal-mask' : ''" v-if="searchResults.length != 0"
                            @click.self="hideSearchResults()">
                            <div class="modal-container">
                                <ul v-show="showSearchResults" :class="hiddenClass ? 'hide list-group' : 'list-group'"
                                    type="search" style="position: absolute; top: 3rem; right: 1.8rem; z-index: 10000">
                                    <li @click="rowClick(result)" v-for="result in searchResults" :key="result.id"
                                        class="list-group-item">
                                        {{result.name}}</li>
                                </ul>
                            </div>
                        </div>
                    </teleport>
                </li>
            </ul>
        </div> -->
    </nav>
</template>

<script>
    import axios from 'axios'
    import Auth from '@aws-amplify/auth'
    import VueCookies from 'vue-cookies'

    export default {
        name: 'NavBar',
        data() {
            return {
                hiddenClass: false,
                username: "",
                email: "",
                selectedLink: "",
                selectedSubLink: "",
                search: "",
                searchResults: [],
                showSearchResults: false,
                showModalMask: false
            }
        },
        methods: {
            logout() {

                this.$store.dispatch('logout')

            },
            hideLogout() {
                this.hiddenClass = !this.hiddenClass
            },
            searchCRM() {

                if (this.$store.getters.getCurrentTime > this.$store.getters.getSessionTimeout) {
                    this.$store.dispatch('logout')
                }
                this.$store.dispatch('refreshToken')
                const headers = {
                    'x-orca-accesstoken': this.$store.getters.getJwtToken,
                };

                axios.get(process.env.VUE_APP_ROOT_API + "search?search=" + this.search, {
                        headers
                    })
                    .then(response => {
                        this.searchResults = response.data.body

                    })
                    .catch(axiosError => {
                        if (axiosError.response){
                            this.$store.dispatch("logMessage", axiosError.response.data.developerMessage + " on Endpoint: " + axiosError.config?.url)
                        }
                        else {
                            this.$store.dispatch("logMessage", axiosError + " on Endpoint: " + axiosError.config?.url)
                        }
                    })
            },
            hideSearchResults() {
                this.showSearchResults = false
                this.showModalMask = false
            },
            rowClick(payload) {
                let obj = payload

                if (obj.type === 'Opportunity') {

                    this.$router.push({
                        path: `/crm/opportunities/${obj.id}`
                    })


                } else if (obj.type === 'Account') {

                    this.$router.push({
                        path: `/crm/accounts/${obj.id}`
                    })

                } else if (obj.type === 'Contact') {

                    this.$router.push({
                        path: `/crm/contacts/${obj.id}`
                    })


                }

                if (this.$route.path.includes("crm/contacts/") || this.$route.path.includes("crm/accounts/" || this
                        .$route.path.includes("crm/opportunities/"))) {
                    if (obj.type === 'Opportunity' && this.$route.path.includes("crm/opportunities/")) {
                        window.location.href = `/crm/opportunities/${obj.id}`
                    } else if (obj.type === 'Account' && this.$route.path.includes("crm/accounts/")) {
                        window.location.href = `/crm/accounts/${obj.id}`
                    } else if (obj.type === 'Contact' && this.$route.path.includes("crm/contacts/")) {
                        window.location.href = `/crm/contacts/${obj.id}`
                    } else {
                        if (obj.type === 'Opportunity') {

                            this.$router.push({
                                path: `/crm/opportunities/${obj.id}`
                            })


                        } else if (obj.type === 'Account') {

                            this.$router.push({
                                path: `/crm/accounts/${obj.id}`
                            })

                        } else if (obj.type === 'Contact') {

                            this.$router.push({
                                path: `/crm/contacts/${obj.id}`
                            })
                        }

                    }
                } else {

                    if (obj.type === 'Opportunity') {

                        this.$router.push({
                            path: `/crm/opportunities/${obj.id}`
                        })


                    } else if (obj.type === 'Account') {

                        this.$router.push({
                            path: `/crm/accounts/${obj.id}`
                        })

                    } else if (obj.type === 'Contact') {

                        this.$router.push({
                            path: `/crm/contacts/${obj.id}`
                        })
                    }

                }



                this.search = ""
                this.showSearchResults = false
            }
        },
        async mounted() {
            const user = await Auth.currentAuthenticatedUser()
            this.username = user.username
            this.email = user.attributes.email
            VueCookies.set("selectedLink", "home", "1d")
            VueCookies.set("selectedSubLink", 0, "1d")

        },

        watch: {
            search: function () {
                if (this.search.length > 2) {
                    this.showSearchResults = true
                    this.showModalMask = true
                    this.searchCRM()

                } else if (this.search.length < 3) {
                    this.searchResults = []
                }
            },
            '$route'() {
                if (this.$route.path === "/") {
                    this.selectedLink = "home"
                    this.selectedSubLink = 0
                } else if (this.$route.path === "/crm/dashboard") {
                    this.selectedLink = "crm"
                    this.selectedSubLink = 0
                } else if (this.$route.path.includes("/crm/accounts")) {
                    this.selectedLink = "crm"
                    this.selectedSubLink = 1
                } else if (this.$route.path.includes("/crm/contacts")) {
                    this.selectedLink = "crm"
                    this.selectedSubLink = 2
                } else if (this.$route.path.includes("/crm/opportunities")) {
                    this.selectedLink = "crm"
                    this.selectedSubLink = 3
                } else if (this.$route.path === "/crm/activities") {
                    this.selectedLink = "crm"
                    this.selectedSubLink = 4
                } else if (this.$route.path === "/crm/ctr") {
                    this.selectedLink = "crm"
                    this.selectedSubLink = 5
                } else if (this.$route.path === "/diag/priority") {
                    this.selectedLink = "diag"
                    this.selectedSubLink = 1
                } else if (this.$route.path === "/diag/status") {
                    this.selectedLink = "diag"
                    this.selectedSubLink = 2
                } else if (this.$route.path === "/diag/assets") {
                    this.selectedLink = "diag"
                    this.selectedSubLink = 3
                } else if (this.$route.path === "/orders/fulfillment") {
                    this.selectedLink = "order"
                    this.selectedSubLink = 1
                } else if (this.$route.path === "/orders/completed") {
                    this.selectedLink = "order"
                    this.selectedSubLink = 2
                } else if (this.$route.path === "/sku/check-in") {
                    this.selectedLink = "sku"
                    this.selectedSubLink = 1
                } else if (this.$route.path === "/sku/sim-modem") {
                    this.selectedLink = "sku"
                    this.selectedSubLink = 2
                } else if (this.$route.path === "/sku/cameras") {
                    this.selectedLink = "sku"
                    this.selectedSubLink = 3
                } else if (this.$route.path === "/sku/enclosures") {
                    this.selectedLink = "sku"
                    this.selectedSubLink = 4
                } else if (this.$route.path === "/sku/lenses") {
                    this.selectedLink = "sku"
                    this.selectedSubLink = 5
                } else if (this.$route.path === "/sku/modems") {
                    this.selectedLink = "sku"
                    this.selectedSubLink = 6
                } else if (this.$route.path === "/sku/power-supplies") {
                    this.selectedLink = "sku"
                    this.selectedSubLink = 7
                } else if (this.$route.path === "/sku/rasp-pi") {
                    this.selectedLink = "sku"
                    this.selectedSubLink = 8
                } else if (this.$route.path === "/sku/sim") {
                    this.selectedLink = "sku"
                    this.selectedSubLink = 9
                } else if (this.$route.path === "/sku/tracker") {
                    this.selectedLink = "sku"
                    this.selectedSubLink = 10
                }

            }
        },


    }
</script>


<style scoped>
    .hide {
        display: none;
    }

    @media all and (max-width: 978px) {

        #navbarBrandProject {
            display: none;
        }

    }

    @media all and (min-width: 778px) {
        .navbar .nav-item .dropdown {
            display: none;
        }

        .navbar .nav-item:hover .dropdown {
            display: block;
        }

        .navbar .nav-item .dropdown {
            margin-top: 0;
        }
    }

    .list-group-item:hover {
        cursor: pointer;
        background-color: grey;

    }

    .modal-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    nav {
        z-index: 100;
    }
</style>