import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import axios from "axios";
import VueAxios from "vue-axios";
import axiosRetry from "axios-retry";
import store from "./store/store";
import VueCookies from "vue3-cookies";
import vClickOutside from "v-click-outside";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faAngleUp,
  faSort,
  faRedo,
  faCheckCircle,
  faTimes,
  faPlus,
  faCalendarAlt,
  faSearch,
  faEyeSlash,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import PrimeVue from "primevue/config";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import ToastService from 'primevue/toastservice';
import "primeicons/primeicons.css";

import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";

library.add(
  faAngleDown,
  faAngleUp,
  faSort,
  faRedo,
  faCheckCircle,
  faTimes,
  faPlus,
  faCalendarAlt,
  faSearch,
  faEyeSlash,
  faCopy,
  faLink
);

awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;

Amplify.configure(awsconfig);

createApp(App)
  .use(VueCookies)
  .use(router)
  .use(VueAxios, axios, axiosRetry)
  .use(store)
  .use(vClickOutside)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(PrimeVue)
  .use(ToastService)
  .component("Calendar", Calendar)
  .component("Dropdown", Dropdown)
  .mount("#app");
