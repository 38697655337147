<template>
  <div>
    <div v-if="!['404','Login'].includes($route.name)">
      <NavBar />
    </div>
  <div class="marginTop">
     <router-view />
  </div>

  <Footer />
  </div>
</template>
<script>
  import NavBar from './components/NavBar'
  import Footer from './components/Footer'

  export default {
    components: {
      NavBar,
      Footer
    },
    async mounted() {

      this.$store.dispatch('setSessionTimeout')

    },
    async created() {
      setInterval(() => this.$store.dispatch('refreshToken'), 3300000);
    }

  }
</script>

<style scoped>
  .marginTop {
    margin-top: 5em;
  }
</style>