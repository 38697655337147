import { createWebHistory, createRouter } from "vue-router";
import {Auth} from 'aws-amplify'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home.vue'),
  },
  {
    path: '/diag/priority',
    name: 'Diag Priority',
    component: () => import('../views/diag/diag-priority.vue')
  },
  {
    path: '/diag/priority/cameras/:id',
    name: 'Diag Priority Cameras Details',
    component: () => import('../views/diag/diag-cameras-details.vue'),
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login.vue')
  },
  {
    path: "/:catchAll(.*)",
    name: '404',
    component: () => import('../views/errors/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async(to, from, next) => {
  try {
    document.title = "Orca IoT - " + to.name
    var user = await Auth.currentUserInfo();
    // if (this.$store.getters.getCurrentTime > this.$store.getters.getSessionTimeout){
    //   this.$store.dispatch('logout')
    // }
    if (user == null && to.path !== '/login' && to.name !== '404') {
      next('/login')
    } else {
      next()
    }
  } catch (e) {
    next('/login')
  }

})

// router.beforeEach(async(to) => {
//   document.title = "Orca IoT - " + to.name

// })

export default router;

